IMAGEMAP = {
	"usecase_01_adtech_bcn_comp_003_hr_01": "usecase_01_adtech_bcn_comp_003_hr_01_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_02": "usecase_01_adtech_bcn_comp_003_hr_02_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_03": "usecase_01_adtech_bcn_comp_003_hr_03_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_04": "usecase_01_adtech_bcn_comp_003_hr_04_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_05": "usecase_01_adtech_bcn_comp_003_hr_05_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_06": "usecase_01_adtech_bcn_comp_003_hr_06_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_07": "usecase_01_adtech_bcn_comp_003_hr_07_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_08": "usecase_01_adtech_bcn_comp_003_hr_08_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_09": "usecase_01_adtech_bcn_comp_003_hr_09_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_10": "usecase_01_adtech_bcn_comp_003_hr_10_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_11": "usecase_01_adtech_bcn_comp_003_hr_11_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_12": "usecase_01_adtech_bcn_comp_003_hr_12_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_13": "usecase_01_adtech_bcn_comp_003_hr_13_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_14": "usecase_01_adtech_bcn_comp_003_hr_14_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_15": "usecase_01_adtech_bcn_comp_003_hr_15_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_16": "usecase_01_adtech_bcn_comp_003_hr_16_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_17": "usecase_01_adtech_bcn_comp_003_hr_17_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_18": "usecase_01_adtech_bcn_comp_003_hr_18_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_19": "usecase_01_adtech_bcn_comp_003_hr_19_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_20": "usecase_01_adtech_bcn_comp_003_hr_20_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_21": "usecase_01_adtech_bcn_comp_003_hr_21_loop.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_22": "usecase_01_adtech_bcn_comp_003_hr_22_transition.mp4",
	"usecase_01_adtech_bcn_comp_003_hr_23": "usecase_01_adtech_bcn_comp_003_hr_23_loop.mp4",
	"AdTech_insight_Challenge_1_icon": "AdTech_insight_Challenge_1_icon.svg",
	"AdTech_insight_Challenge_2_icon": "AdTech_insight_Challenge_2_icon.svg",
	"AdTech_insight_Solution_1_icon": "AdTech_insight_Solution_1_icon.svg",
	"AdTech_insight_Solution_2_icon": "AdTech_insight_Solution_2_icon.svg",
	"AdTech_insight_Solution_3_icon": "AdTech_insight_Solution_3_icon.svg",
	"AdTech_insight_Solution_4_icon": "AdTech_insight_Solution_4_icon.svg",
	"AdTech_insight_MS1_1_icon": "AdTech_insight_MS1_1_icon.svg",
	"AdTech_insight_MS2_1_icon": "AdTech_insight_MS2_1_icon.svg",
	"AdTech_insight_MS2_2_icon": "AdTech_insight_MS2_2_icon.svg",
	"AdTech_insight_MS2_3_icon": "AdTech_insight_MS2_3_icon.svg",
	"AdTech_insight_MS3_1_icon": "AdTech_insight_MS3_1_icon.svg",
	"AdTech_insight_MS3_2_icon": "AdTech_insight_MS3_2_icon.svg",
	"AdTech_insight_Summary_1_icon": "AdTech_insight_Summary_1_icon.svg",
	"AdTech_insight_Summary_2_icon": "AdTech_insight_Summary_2_icon.svg"
}
